<template>
<v-dialog v-model="dialog" width="500">
  <v-card>
    <v-container class="pa-4" fluid>
      <div class="d-flex">
        <div class="text-subtitle-1 font-weight-bold">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </div>
      <div class="mt-3">
        <v-divider></v-divider>
      </div>
      <div class="mt-3">
        <v-btn color="grey" outlined>
          <div>okre</div>
          <v-spacer></v-spacer>
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </div>
      <div style="height:350px;overflow:auto;">
        <v-data-table :headers="headers" :items="users">
        </v-data-table>
      </div>
      <div class="justify-end d-flex mt-3">
        <v-btn @click="dialog=false" color="primary" small>我知道了</v-btn>
      </div>
    </v-container>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: 'SettingUsersDlg',
  props: ['users', 'title'],
  data() {
    return {
      dialog: false,
      headers: [{
          text: '姓名',
          align: 'start',
          value: 'employeeName'
        },
        {
          text: '部门',
          align: 'start',
          value: 'departmentOaName'
        },
        {
          text: '	目标数量',
          align: 'start',
          value: ''
        }
      ]
    }
  },
  methods: {
    open() {
      this.dialog = true;
    }
  }
}
</script>
